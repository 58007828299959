/**
 *  Main component for Quizzes - parses the html from a lecture page, gets
 *  the necessary data and formats it, then mounts a Quiz React component
 *  and passes that data in as props.
 */

import React from 'react'
import ReactDOM from 'react-dom'
import getDataAttributes from 'student/shared/util/get-data-attributes'
import { LectureCompletionDataType } from '../../types'
import { Quiz } from './Quiz'
import { serializeQuizItems } from './util'

const ATTRIBUTE_PROGRESS = 'data-grade'
const ATTRIBUTE_SCHEMA = 'data-schema'
const ATTRIBUTE_STRINGS = 'data-quiz-strings'
const SELECTOR_GRADE = `[${ATTRIBUTE_PROGRESS}]`
const SELECTOR_SCHEMA = `[${ATTRIBUTE_SCHEMA}]`
const SELECTOR_STRINGS = `[${ATTRIBUTE_STRINGS}]`

function info(msg) {
  console.info('[Quiz app]:', msg)
}

function findQuizContainerElement(): HTMLElement | null {
  let $el = document.querySelector(SELECTOR_SCHEMA)

  // The fallback option if someone customized their quiz attachment HTML.
  if (!$el) {
    const $quiz = document.querySelectorAll('.lecture-attachment-type-quiz')

    if ($quiz.length > 1) {
      info('More than one quiz on a page, exiting.')
      return null
    }

    if ($quiz.length) {
      $el = document.createElement('div')

      $quiz[0].appendChild($el)
    }
  }

  return $el as HTMLElement
}

function formatData(answers, data, progressData) {
  const newAnswers = answers
  const newData = data
  const newGrade = {
    answered: progressData.grade.answered,
    correct: progressData.grade.correct,
    total: progressData.grade.total,
  }

  Object.values(progressData.answers).forEach((current, index) => {
    newAnswers[index] = {
      correct: {},
      user: current,
    }

    // This check is pretty edge-case, it only happens when a student takes
    // a graded quiz and then the teacher edits the quiz and reduces the
    // total question count. Probably more likely to run into it in testing
    // than production.
    if (newData.data.answerKey[`answer-${index + 1}`]) {
      newData.data.answerKey[`answer-${index + 1}`].forEach((choice) => {
        newAnswers[index].correct[choice] = true
      })
    }
  })

  return { answers: newAnswers, data: newData, grade: newGrade }
}

function getSchemaAndStrings(el, data) {
  // One quiz per page for now.
  const $strings = document.querySelector(SELECTOR_STRINGS)

  if (!el || !$strings) {
    info('Missing elements required to render Quizzes.')
    return null
  }

  const schema = serializeQuizItems(data.schema.properties)
  if (!schema.length) {
    info('Found quiz with no questions, exiting.')
    return null
  }

  const { quizStrings } = getDataAttributes($strings)
  const { check, error, label, next, previous, scored, retake } = quizStrings

  if (
    typeof check === 'undefined' ||
    typeof error === 'undefined' ||
    typeof label === 'undefined' ||
    typeof next === 'undefined' ||
    typeof previous === 'undefined' ||
    typeof scored === 'undefined' ||
    typeof retake === 'undefined'
  ) {
    info(
      'Missing required strings. Please provide custom text strings for the "quiz" section'
    )
    return null
  }

  return { schema, quizStrings }
}

export async function initQuizzes(
  lectureCompletionData: LectureCompletionDataType
) {
  const $el = findQuizContainerElement()
  if (!$el) {
    return
  }

  let data = getDataAttributes($el)
  const schemaAndStrings = getSchemaAndStrings($el, data)

  if (!schemaAndStrings) {
    return
  }

  const $grade = document.querySelector(SELECTOR_GRADE)
  let answers = {}
  let grade

  if ($grade) {
    const progressData = getDataAttributes($grade)
    const formattedData = formatData(answers, data, progressData)

    answers = formattedData.answers
    data = formattedData.data
    grade = formattedData.grade
  }

  renderQuiz($el, {
    answers,
    grade,
    data,
    lectureCompletionData,
    schema: schemaAndStrings.schema,
    quizStrings: schemaAndStrings.quizStrings,
  })
}

function renderQuiz(element, data: any) {
  ReactDOM.render(
    <Quiz
      answers={Object.values(data.answers)}
      grade={data.grade}
      id={data.data.id}
      isGraded={data.data.graded || false}
      isPublished={data.data.isPublished}
      lectureCompletionData={data.lectureCompletionData}
      questions={data.schema}
      strings={data.quizStrings}
    />,
    element
  )
}
