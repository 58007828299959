import onReady from './util/on-ready'

const ATTR = 'data-imageloader'
const ATTR_CURRENT_SIZE = `${ATTR}-current-size`
const ATTR_SRC = `${ATTR}-src`
const ATTR_FS_ID = `${ATTR}-fs_id`

const ERROR_IMAGE = '[imageloader]: The first argument must be an HTMLElement.'
const ERROR_PARENT = '[imageloader]: Parent has no width.'
const ERROR_URL = '[imageloader]: Could not find image URL.'

const FILESTACK_KEY = 'ADNupMnWyR7kCWRvm76Laz'
const FILESTACK_BASE_URL = 'https://cdn.fs.teachablecdn.com'

const SIZES = [250, 500, 750, 1000, 1500, 2500]

const getImageUrl = ($image) => $image.getAttribute(ATTR_SRC)
const getFilestackId = ($image) => $image.getAttribute(ATTR_FS_ID)
const getCurrentSize = ($image) =>
  parseInt($image.getAttribute(ATTR_CURRENT_SIZE), 10)
const getDevicePixelRatio = () => devicePixelRatio || 1
const getFilestackUrlWithKey = (width) =>
  `${FILESTACK_BASE_URL}/${FILESTACK_KEY}/resize=width:${width}`
const getImageSize = (width) =>
  SIZES.find((size) => size >= width) || SIZES[SIZES.length - 1]
const getMinWidth = (width) => width * getDevicePixelRatio()
const buildResponsiveUrlForUrl = (url, width) =>
  `${getFilestackUrlWithKey(width)}/${url}`
const buildResponsiveUrlForFsId = (fsId, width) =>
  `${FILESTACK_BASE_URL}/resize=width:${width}/${fsId}`

// Automatically load all images on page load (and on turbolinks page:ready).
onReady(loadAllImages)

/**
 * Handles all the DOM stuff on the image.
 *
 * @private
 * @param   {HTMLElement} $image
 * @param   {Object}      data
 */
function setImageAttributes($image, data) {
  $image.setAttribute('src', data.url)
  $image.setAttribute(ATTR_CURRENT_SIZE, data.size || '')

  if ($image.className.indexOf('is-loaded') === -1) {
    const handleLoad = () => $image.classList.add('is-loaded')

    $image.addEventListener('load', handleLoad, { once: true })
  }
}

function getAncestorWithConditions($el, conditionResolver) {
  let result

  const checkConditions = ($current) => {
    if (conditionResolver($current)) {
      result = $current
    }

    if (!result && $current.parentElement) {
      checkConditions($current.parentElement)
    }
  }

  checkConditions($el)

  return result
}

/**
 * Loads an image based on the size of the parent element.
 *
 * @param   {HTMLElement} $image  The image element.
 * @returns {Promise}
 */
export function loadImage($image) {
  return new Promise((resolve, reject) => {
    if (!($image instanceof HTMLElement)) {
      return reject(ERROR_IMAGE)
    }

    const baseUrl = getImageUrl($image)
    if (!baseUrl) {
      return reject(ERROR_URL)
    }

    if ($image.getAttribute('data-imageloader-skip-resize') === 'true') {
      setImageAttributes($image, { url: baseUrl })
      return resolve({ size: '' })
    }

    const $ancestor = getAncestorWithConditions(
      $image.parentElement,
      ($el) => $el.offsetWidth
    )
    const ancestorWidth = $ancestor ? $ancestor.offsetWidth : undefined
    if (!ancestorWidth) {
      return reject(ERROR_PARENT)
    }

    const currentSize = getCurrentSize($image)
    const size = getImageSize(getMinWidth(ancestorWidth))
    if (currentSize && size <= currentSize) {
      // Don't download the same image twice. Also don't download a smaller
      // image if we've already got a larger one.
      return resolve({ size: currentSize })
    }

    const fsId = getFilestackId($image)
    const url = fsId
      ? buildResponsiveUrlForFsId(fsId, size)
      : buildResponsiveUrlForUrl(baseUrl, size)
    setImageAttributes($image, { size, url: url })

    return resolve({ size })
  })
}

/**
 * Loads all image on a page.
 *
 * @returns {Promise}
 */
export function loadAllImages() {
  const $images = document.querySelectorAll(`img[${ATTR}]`)
  const imagePromises = Array.prototype.map.call($images, loadImage)

  return Promise.all(imagePromises)
}
