import { VideoRangesType, VideoRangeType, VideoTimeRangeType } from '../types'

// Compares a new timestamp against a collated array of current timestamps.
// Either merges it in with a current one if there is an overlap, or adds
// it to the array if not (which means the user skipped ahead in the video).
export function collateTimestamps(
  timestamps: VideoRangesType | undefined,
  time: number
): VideoTimeRangeType[] {
  const newTimestamp: VideoTimeRangeType = [time, time]

  if (!timestamps) {
    return [newTimestamp]
  }

  let newTimestampMerged = false
  const collated: VideoTimeRangeType[] = [...timestamps]

  // Loop through all of the current timestamps and see if the new
  // one overlaps with any of them - if so, merge it.
  const merged: VideoTimeRangeType[] = collated.reduce(
    (acc: VideoTimeRangeType[], cur: VideoTimeRangeType) => {
      if (!newTimestampMerged) {
        const curCopy = [...cur] as VideoTimeRangeType

        if (timestampsOverlap(curCopy, newTimestamp)) {
          curCopy[0] = Math.min(curCopy[0], newTimestamp[0])
          curCopy[1] = Math.max(curCopy[1], newTimestamp[1])
          newTimestampMerged = true
        }

        return [...acc, curCopy]
      }

      return acc
    },
    []
  )

  // If the new timestamp wasn't merged it means the user
  // skipped some of the video, so push it into the array.
  if (!newTimestampMerged) {
    merged.push(newTimestamp)
  }
  if (merged.sort(sortByZeroIndex).length === 0) return [[0, 0]]
  return merged.sort(sortByZeroIndex)
}

const timestampsOverlap = (timestamp1, timestamp2) => {
  // If the timestamp array looks like [[1, 1], [2, 2]] - these technically
  // don't overlap. There's a 1 second gap between the first and second.
  // Setting the overlapThreshold to 1 fixes that, it basically means any gap of
  // the overlapThreshold amount or less is still treated as an overlap.
  const overlapThreshold = 1

  return (
    (timestamp1[0] - overlapThreshold <= timestamp2[1] &&
      timestamp1[1] - overlapThreshold >= timestamp2[0]) ||
    (timestamp2[0] - overlapThreshold <= timestamp1[1] &&
      timestamp2[1] - overlapThreshold >= timestamp1[0])
  )
}

export function getStartTime(ranges: VideoRangesType | undefined): number {
  if (!ranges || !ranges.length) {
    return 0
  }

  if (ranges[0][0] > 0) {
    return 0
  }

  return ranges[0][1]
}

function sortByZeroIndex(a: VideoRangeType, b: VideoRangeType): -1 | 1 | 0 {
  if (a[0] < b[0]) return -1

  if (a[0] > b[0]) return 1

  return 0
}
