import cx from 'classnames'
import React from 'react'
import { getCurrentUser } from 'student/shared/util/user'
import { emitStudentSideEvent } from '../../util/analytics-util'
import { ControlsProps } from './types'

export const Controls = ({
  actions,
  canAdvance,
  canCheck,
  canRecede,
  id,
  isChecking,
  strings,
}: ControlsProps): JSX.Element => {
  const trackCheckAnswerClick = (element: HTMLElement): void => {
    emitStudentSideEvent('clicked', element)
    actions.submitAnswer()
  }

  const trackGoToNextClick = (element: HTMLElement): void => {
    emitStudentSideEvent('clicked', element)
    actions.goToNext()
  }

  const trackGoToPreviousClick = (element: HTMLElement): void => {
    emitStudentSideEvent('clicked', element)
    actions.goToPrevious()
  }

  const user = getCurrentUser()

  return (
    <div className="quiz-controls">
      <button
        className={cx('btn btn-primary pull-right check-answer-button', {
          'is-visible': canCheck,
          'half-opacity': isChecking,
        })}
        date-test="check-answer-button"
        onClick={(e) => trackCheckAnswerClick(e.currentTarget)}
        aria-hidden={!canCheck}
        disabled={!canCheck}
        aria-label="submit answer"
        data-ss-event-name="Quiz: Check answer"
        data-ss-event-type="button"
        data-ss-quiz-id={id}
        data-ss-school-id={user.schoolId}
        data-ss-user-id={user.id}
      >
        {strings.check}
      </button>
      <button
        className={cx('btn btn-primary pull-right', {
          'is-visible': canAdvance,
        })}
        date-test="continue-button"
        onClick={(e) => trackGoToNextClick(e.currentTarget)}
        aria-hidden={!canAdvance}
        disabled={!canAdvance}
        aria-label="continue"
        data-ss-event-name="Quiz: Go to next question"
        data-ss-event-type="button"
        data-ss-quiz-id={id}
        data-ss-school-id={user.schoolId}
        data-ss-user-id={user.id}
      >
        {strings.next} &#8250;
      </button>
      <button
        className={cx('btn btn-primary pull-left', { 'is-visible': canRecede })}
        onClick={(e) => trackGoToPreviousClick(e.currentTarget)}
        aria-hidden={!canRecede}
        disabled={!canRecede}
        aria-label="go back"
        data-ss-event-name="Quiz: Go to previous question"
        data-ss-event-type="button"
        data-ss-quiz-id={id}
        data-ss-school-id={user.schoolId}
        data-ss-user-id={user.id}
      >
        &#8249; {strings.previous}
      </button>
    </div>
  )
}
