import { initUpsells } from '@teachable/widgets'
import { get } from 'lodash'
import splitCamelCaseString from 'src/util/split-camel-case-string'
import getDataAttributes from 'student/shared/util/get-data-attributes'
import onReady from 'student/shared/util/on-ready'
import { LectureCompletionDataType } from '../types'
import { initDrip } from './drip'
import { initQuizzes } from './quiz'
import { initVideos } from './video'

export const PREVIOUS_BUTTON_ID = 'lecture_previous_button'
export const COMPLETE_BUTTON_ID = 'lecture_complete_button'
export const COMPLIANCE_DATA_ID = 'lecture-completion-data'
export const FEDORA_DATA_ID = 'fedora-data'

export const getLockedCompleteButtonTooltipText = () => {
  let tooltipText = ''
  const completeButton = document.getElementById(COMPLETE_BUTTON_ID)

  if (completeButton && get(completeButton, 'dataset.cplTooltip')) {
    tooltipText = String(completeButton.dataset.cplTooltip)
  }

  return tooltipText
}

export const getLockedVideoCompleteButtonTooltipText = (
  progressCompleted: string = ''
) => {
  let tooltipText = ''
  const completeButton = document.getElementById(COMPLETE_BUTTON_ID)

  if (completeButton && get(completeButton, 'dataset.vplTooltip')) {
    tooltipText = String(completeButton.dataset.vplTooltip)
    tooltipText = tooltipText.replace(
      '%{progress_completed}',
      progressCompleted
    )
  }

  return tooltipText
}

const getComplianceData = (
  dataEl: HTMLElement | null
): LectureCompletionDataType => {
  const formatted = dataEl ? getDataAttributes(dataEl) : {}

  return Object.keys(formatted).reduce((newData, key) => {
    const splitString = splitCamelCaseString(key)
    let newKey = ''

    if (splitString) {
      newKey = splitString
        .toLowerCase()
        .split(' ')
        .join('_')
        .split('-')
        .join('_')
    }

    newData[newKey] = formatted[key]
    return newData
  }, {}) as LectureCompletionDataType
}

const isLoggedInOwner = () => {
  const data = document.getElementById(FEDORA_DATA_ID)
  return (
    get(data, 'dataset.role') === 'owner' &&
    get(data, 'dataset.preview') === 'logged_in'
  )
}

const preventClick = (e) => {
  e.preventDefault()
  return false
}

export const lockPreviousAndCompleteButtons = () => {
  const prevButton = document.getElementById(PREVIOUS_BUTTON_ID)
  const completeButton = document.getElementById(COMPLETE_BUTTON_ID)

  if (prevButton) {
    prevButton.classList.add('disabled')
    prevButton.addEventListener('click', preventClick)
  }

  if (completeButton) {
    completeButton.classList.add('disabled')
    completeButton.addEventListener('click', preventClick)
  }
}

const setLastLectureUrl = (url) => {
  const lastLectureButton = document.getElementById('last_lecture_button')

  if (url && lastLectureButton) {
    lastLectureButton.setAttribute('href', url)
  }
}

export const unlockPreviousAndCompleteButtons = () => {
  const prevButton = document.getElementById(PREVIOUS_BUTTON_ID)
  const completeButton = document.getElementById(COMPLETE_BUTTON_ID)

  if (prevButton) {
    prevButton.classList.remove('disabled')
    prevButton.removeEventListener('click', preventClick)
  }

  if (completeButton) {
    completeButton.classList.remove('disabled')
    completeButton.removeEventListener('click', preventClick)
  }
}

onReady(async () => {
  const lockedEls = document.getElementsByClassName('locked-progress-view')
  const dataEl = document.getElementById(COMPLIANCE_DATA_ID)
  const lectureCompletionData = getComplianceData(dataEl)
  const completeButton = document.getElementById(COMPLETE_BUTTON_ID)

  if (lockedEls && lockedEls.length) {
    const lastLectureUrl = get(lectureCompletionData, 'last_lecture_url')
    setLastLectureUrl(lastLectureUrl)
  }

  if (
    lectureCompletionData &&
    !get(lectureCompletionData, 'valid_for_completion') &&
    !isLoggedInOwner()
  ) {
    lockPreviousAndCompleteButtons()
  } else {
    unlockPreviousAndCompleteButtons()
  }

  // If the user has navigated away from an uncompleted lecture
  // to another locked lecture, check for a tooltip on the
  // complete button and remove it
  if (completeButton && (completeButton as any).tooltip) {
    ;(completeButton as any).tooltip.destroy()
  }

  initDrip()
  initVideos(lectureCompletionData, dataEl)
  initQuizzes(lectureCompletionData)
  initUpsells('.upsell-app')
})
