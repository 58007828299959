import { cloneDeep, get, omit, throttle } from 'lodash'
import { sub, unsubAll } from 'src/util/pubsub'
import {
  lockPreviousAndCompleteButtons,
  unlockPreviousAndCompleteButtons,
} from '..'
import {
  COMPLETE_BUTTON_ID,
  getLockedCompleteButtonTooltipText,
  getLockedVideoCompleteButtonTooltipText,
} from '..'
import { tooltip } from '../../components/tooltip'
import {
  AttachmentCompletionsResponseType,
  LectureCompletionDataType,
} from '../../types'
import { getControlValue } from '../controls'
import { getCurrentVideos, initializeWistiaVideos } from './lib/wistia'
import {
  VideoCacheItemType,
  VideoCacheType,
  VideoEventEndType,
  VideoEventProgressType,
  VideoEventReadyType,
} from './types'
import { postProgress, sendAnalyticsEvent } from './util/api'
import { getPersistentData, saveToStorage } from './util/storage'
import { collateTimestamps, getStartTime } from './util/time'

const PROGRESS_THROTTLE_TIME = 5000
const MINIMUM_COMPLETED_PERCENTAGE = 0.9

export class LectureVideos {
  cache: VideoCacheType
  autoplayEnabled: boolean
  complianceEnforced: boolean
  complianceIncomplete: boolean
  complianceTooltip: any
  courseId: string
  lectureCompletionData: LectureCompletionDataType
  lectureCompletionDataEl: HTMLElement | null
  lectureId: string
  loggedIn: boolean
  playerElements: HTMLElement[]
  metaData: any

  constructor(
    lectureCompletionData: LectureCompletionDataType,
    playerElements: HTMLElement[] = [],
    metaData: any,
    lectureCompletionDataEl: HTMLElement | null
  ) {
    this.lectureCompletionData = lectureCompletionData
    this.playerElements = playerElements
    this.metaData = metaData
    this.lectureCompletionDataEl = lectureCompletionDataEl

    this.init()
  }

  init = async () => {
    this.removeEventListeners()
    this.addEventListeners()
    const data = await getPersistentData()
    await this.setData(data)
    this.setupCompliance()
    initializeWistiaVideos()
  }

  setData = async (data: VideoCacheType) => {
    const lectureIdRegex = /lectures\/(.*)(\/|$)/g
    const courseIds = get(this.metaData, 'courseIds')

    this.loggedIn = courseIds && courseIds.length > 0
    this.courseId = get(this.metaData, 'courseId')
    this.lectureId = get(lectureIdRegex.exec(window.location.href), '[1]')
    this.complianceEnforced = get(
      this.lectureCompletionData,
      'compliance_requirements.is_video_completion_enforced'
    )
    this.complianceIncomplete = !get(
      this.lectureCompletionData,
      'compliance_requirements.video_completion_requirements_met'
    )
    this.cache = data || {}
    this.autoplayEnabled = await this.getAutoplay()
  }

  getRecord = (cacheId: string) => {
    return this.cache[cacheId]
  }

  updateAttachmentMeta = (data: AttachmentCompletionsResponseType) => {
    if (this.lectureCompletionDataEl) {
      const attachmentsData = this.lectureCompletionDataEl.getAttribute(
        'data-attachments'
      )

      if (attachmentsData) {
        const parsedAttachmentsData = JSON.parse(attachmentsData)
        const newMeta = cloneDeep(parsedAttachmentsData)
        newMeta[data.attachment_id] = data

        const stringifiedAttachmentsData = JSON.stringify(newMeta)
        this.lectureCompletionDataEl.setAttribute(
          'data-attachments',
          stringifiedAttachmentsData
        )
      }
    }
  }

  addEventListeners = () => {
    sub('video:ready', this.onVideoReady)
    sub('video:progress', this.onVideoProgress)
    sub('video:pause', this.onVideoPause)
    sub('video:end', this.onVideoEnd)
  }

  removeEventListeners = () => {
    unsubAll('video:ready')
    unsubAll('video:progress')
    unsubAll('video:pause')
    unsubAll('video:end')
  }

  insertComplianceTooltip = (videoProgressMessage: string = '') => {
    const completeButton = document.getElementById(COMPLETE_BUTTON_ID)

    if (completeButton) {
      // Destroy previous tooltip
      if (this.complianceTooltip) {
        this.complianceTooltip.destroy()
      }

      const formatMessage = `${getLockedCompleteButtonTooltipText()} ${videoProgressMessage}`

      this.complianceTooltip = tooltip(
        formatMessage,
        completeButton,
        'locked-complete-button'
      )
    }
  }

  setupCompliance = () => {
    if (this.complianceEnforced && this.complianceIncomplete) {
      this.playerElements.forEach((element) => {
        element.setAttribute('data-enforce-compliance', 'true')
      })

      if (!this.complianceTooltip) {
        this.insertComplianceTooltip()
      }
    }
  }

  getAutoplay = (): Promise<boolean> => {
    return new Promise((resolve) => {
      const mdnzr = (window as any).Modernizr
      if (mdnzr) {
        mdnzr.on('videoautoplay', (result) => {
          if (!result) {
            resolve(false)
          }

          resolve(getControlValue('autoplay'))
        })
      } else {
        resolve(false)
      }
    })
  }

  getAutoplayId = (
    videos: string[],
    cache: VideoCacheType
  ): string | undefined => {
    let result

    if (videos) {
      result = videos[0]

      if (!cache) {
        return result
      }

      for (let i = 0; i < videos.length; i += 1) {
        const cacheId = Object.keys(cache).find((key) => {
          return key.indexOf(String(videos[i])) > -1
        })

        if (cacheId) {
          const record = cache[cacheId]

          if (!record) {
            // If we get to a video that's not in the cache, that's the one.
            result = videos[i]
            break
          } else if (!record.isComplete) {
            // If the video's in the cache but not complete, use that one.
            result = videos[i]
            break
          }
        }
      }
    }

    return result
  }

  getCacheId = (id: string, attachmentId: string): string => {
    return `${id}___${attachmentId}___cmp-${this.complianceEnforced}`
  }

  addToCache = (attachmentId: string, id: string, duration: number) => {
    const cacheId = this.getCacheId(id, attachmentId)

    if (!this.cache[cacheId] || this.cache[cacheId].duration !== duration) {
      this.cache[cacheId] = {
        duration,
        farthestTimeWatched: 0,
        attachmentId,
      }
    }
  }

  saveProgress = async (cacheId: string) => {
    const record = this.getRecord(cacheId)
    const progressResponse = await postProgress(this.courseId, this.lectureId, {
      attachment_id: record.attachmentId,
      timestamp_data: record.timestamps,
    })
    const durationFromResponse = get(
      progressResponse,
      'completion_data.duration'
    )

    if (!durationFromResponse) {
      record.noSavedDuration = true
      saveToStorage(this.cache)
      return
    }

    // Only update/save if the duration in the database is not 0 or null.
    const complete = get(
      progressResponse,
      'lecture_completion_data.valid_for_completion'
    )
    const filtered = omit(
      progressResponse,
      'lecture_completion_data'
    ) as AttachmentCompletionsResponseType

    this.updateAttachmentMeta(filtered)

    if (this.complianceEnforced) {
      // Add updated tooltip
      const progress = get(
        progressResponse,
        'completion_data.percentage_completed'
      )
      const formattedProgress: string = progress.toLocaleString('en', {
        style: 'percent',
      })
      this.insertComplianceTooltip(
        `${getLockedVideoCompleteButtonTooltipText(formattedProgress)}`
      )
    }
    if (complete) {
      record.isComplete = true
      saveToStorage(this.cache)
      this.unlockNav()
    }
  }

  throttledSaveProgress = throttle((cacheId) => {
    this.saveProgress(cacheId)
  }, PROGRESS_THROTTLE_TIME)

  updateFarthestTimeWatched = (record: VideoCacheItemType) => {
    const ftw = get(record, 'timestamps[0][1]', -1)

    if (ftw && ftw > record.farthestTimeWatched) {
      record.farthestTimeWatched = ftw
    }
  }

  lockNav = () => {
    lockPreviousAndCompleteButtons()
  }

  unlockNav = () => {
    unlockPreviousAndCompleteButtons()

    if (this.complianceTooltip) {
      this.complianceTooltip.destroy()
    }
  }

  onVideoReady = ({
    attachmentId,
    duration,
    id,
    play,
    getTime,
    setTime,
  }: VideoEventReadyType) => {
    const cacheId = this.getCacheId(id, attachmentId)
    const record = this.getRecord(cacheId)

    if (!record) {
      this.addToCache(attachmentId, id, duration)
      saveToStorage(this.cache)
    }

    const shouldLockButtons =
      this.complianceEnforced &&
      this.loggedIn &&
      record &&
      !record.isComplete &&
      record.noSavedDuration &&
      record.duration &&
      record.farthestTimeWatched <
        record.duration * MINIMUM_COMPLETED_PERCENTAGE

    // If the video attachment was saved with a duration of null or 0,
    // check the cache to see how far the video has been watched. If it's less
    // than 90% of the video, and course compliance is enabled,
    // lock the prev/next buttons.
    if (shouldLockButtons) {
      this.lockNav()
    }

    if (!this.autoplayEnabled) {
      return
    }

    const allVideos = getCurrentVideos()
    const autoplayId = this.getAutoplayId(allVideos, this.cache)

    if (autoplayId && autoplayId === id) {
      const record = this.getRecord(cacheId)
      const { timestamps } = record
      const startTime = getStartTime(timestamps)
      const currentTime = getTime()

      if (currentTime < startTime) {
        setTime(startTime)
      }

      play()
    }
  }

  onVideoProgress = async ({
    attachmentId,
    id,
    percentWatched,
    time,
  }: VideoEventProgressType) => {
    sendAnalyticsEvent(id, percentWatched, { name }, this.lectureId)

    const cacheId = this.getCacheId(id, attachmentId)
    const record = this.getRecord(cacheId)

    if (!record) {
      // This probably will never happen.
      return
    }

    record.timestamps = collateTimestamps(record.timestamps, time)
    this.updateFarthestTimeWatched(record)
    saveToStorage(this.cache)

    if (this.complianceEnforced && this.loggedIn) {
      this.throttledSaveProgress(cacheId)
    }
  }

  onVideoPause = async ({
    attachmentId,
    id,
    percentWatched,
    time,
  }: VideoEventProgressType) => {
    sendAnalyticsEvent(id, percentWatched, { name }, this.lectureId)

    const cacheId = this.getCacheId(id, attachmentId)
    const record = this.getRecord(cacheId)

    if (!record) {
      // This probably will never happen.
      return
    }

    record.timestamps = collateTimestamps(record.timestamps, time)

    saveToStorage(this.cache)

    if (
      this.loggedIn &&
      this.complianceEnforced &&
      Number(time) >= Number(this.cache[cacheId].farthestTimeWatched)
    ) {
      // only post progress if the time is greater than or equal to the current farthest
      // time watched - to avoid incorrect data if the user was scrubbing.
      this.saveProgress(cacheId)
      this.updateFarthestTimeWatched(record)
    }
  }

  onVideoEnd = async ({ attachmentId, id, playNext }: VideoEventEndType) => {
    const cacheId = this.getCacheId(id, attachmentId)
    const allVideos = getCurrentVideos()
    const record = this.getRecord(cacheId)

    if (allVideos.length > 0 && allVideos[allVideos.length - 1] === id) {
      // if the entire lecture is completed, advance to the next one
      if (getControlValue('autocomplete')) {
        const completeButton = document.getElementById(
          'lecture_complete_button'
        )
        completeButton && completeButton.click()
      }
    } else if (this.autoplayEnabled) {
      // if there are more videos in the lecture, play the next one
      playNext()
    }

    if (this.loggedIn && this.complianceEnforced) {
      await this.saveProgress(cacheId)
    }

    // reset the progress if the video is complete - so if the user
    // navigates back to this video, it won't start at the end
    record.farthestTimeWatched = 0
    record.timestamps = [[0, 0]]

    saveToStorage(this.cache)
  }
}
