/**
 * Adds css classes to lecture curriculum when locked by drip.
 * That's done with JavaScript to avoid having to break the cache
 * on the back-end. Also adds the text that displays how many days are
 * remaining until the content is available.
 */

import { ReportCardType } from 'src/types/report-card'
import { userType } from 'src/types/user'
import {
  CLOCK_ICON_CLASS,
  COMPLETE_BUTTON_ID,
  COURSE_MAINBAR_SELECTOR,
  COURSE_SECTION_LOCKED_CLASS,
  DATA_META_ID,
  DAYS_LOGGED_IN_SELECTOR,
  DAYS_LOGGED_OUT_SELECTOR,
  DAYS_TO_DRIP_SELECTOR,
  DAYS_UNTIL_DRIPPED_ATTR,
  DRIP_LECTURE_LOCKED_CLASS,
  DRIP_SECTION_CLASS,
  DRIP_SECTION_SELECTOR,
  DRIPPED_BY_DATE_ATTR,
  LOCK_ICON_CLASS,
  LOCKED_DRIP_CLASS,
} from './constants'

export class LockedDripLecture {
  constructor() {
    this.setup()
  }

  setup = () => {
    const dripSections = Array.from(
      document.querySelectorAll(DRIP_SECTION_SELECTOR)
    )

    if (!dripSections.length) {
      return
    }

    const fedoraData = document.getElementById(DATA_META_ID)
    const courseId = fedoraData && fedoraData.dataset.courseId
    const reportCardDataString = fedoraData && fedoraData.dataset.reportCard
    const reportCardDataJSON = reportCardDataString
      ? JSON.parse(reportCardDataString)
      : null
    const reportCard =
      reportCardDataJSON && courseId ? reportCardDataJSON[courseId] : null
    const daysEnrolled =
      reportCard &&
      (reportCard['days_enrolled'] ? reportCard['days_enrolled'] : 0)
    const currentUserGetter = (window as any).currentUser
    const currentUser = currentUserGetter && currentUserGetter()
    const courseMainbar = Array.from(
      document.querySelectorAll(COURSE_MAINBAR_SELECTOR)
    )[0]
    const lectureCompleteButton = document.getElementById(COMPLETE_BUTTON_ID)
    const lockedLectureContainerElement = document.querySelector(
      `.${DRIP_LECTURE_LOCKED_CLASS}`
    )

    this.removeContinueButton(courseMainbar, lectureCompleteButton)
    this.setDaysUntilAvailableMessage(lockedLectureContainerElement)
    this.setCurriculumState(dripSections, reportCard, daysEnrolled, currentUser)
  }

  setCurriculumState = (
    dripSections: Element[],
    reportCard: ReportCardType,
    daysEnrolled: number,
    currentUser: userType
  ) => {
    dripSections.forEach((sectionElement) => {
      const sectionParent = sectionElement.parentElement
      let daysRemaining: number
      const daysUntilDripped = Number(
        sectionElement.getAttribute(DAYS_UNTIL_DRIPPED_ATTR)
      )
      const hasFullDripAccess = reportCard ? reportCard.has_full_access : false

      if (daysUntilDripped > 0) {
        const drippedAttr = sectionElement.getAttribute(DRIPPED_BY_DATE_ATTR)
        const isDrippedByDate = drippedAttr && drippedAttr.toString() === 'true'
        const iconElement = Array.from(
          sectionElement.querySelectorAll(`.${LOCK_ICON_CLASS}`)
        )[0] as HTMLElement

        if (iconElement) {
          iconElement.classList.add(CLOCK_ICON_CLASS)
          iconElement.classList.remove(LOCK_ICON_CLASS)
        }

        if (isDrippedByDate) {
          daysRemaining = daysUntilDripped
        } else {
          daysRemaining = daysUntilDripped - daysEnrolled
        }

        if (
          (hasFullDripAccess.toString() === 'false' || !hasFullDripAccess) &&
          daysRemaining > 0
        ) {
          const daysUntilDripElement = Array.from(
            sectionElement.querySelectorAll(DAYS_TO_DRIP_SELECTOR)
          )[0] as HTMLElement
          const loggedInDaysToDripElement = Array.from(
            sectionElement.querySelectorAll(DAYS_LOGGED_IN_SELECTOR)
          )[0]
          const loggedOutDaysToDripElement = Array.from(
            sectionElement.querySelectorAll(DAYS_LOGGED_OUT_SELECTOR)
          )[0]

          sectionElement.classList.add(DRIP_SECTION_CLASS)
          if (sectionParent) {
            sectionParent.classList.add(COURSE_SECTION_LOCKED_CLASS)
          }

          if (daysUntilDripElement) {
            daysUntilDripElement.innerHTML = String(daysRemaining)

            if (currentUser && currentUser.id && loggedInDaysToDripElement) {
              ;(loggedInDaysToDripElement as HTMLElement).style.display =
                'block'
            } else if (loggedInDaysToDripElement) {
              ;(loggedOutDaysToDripElement as HTMLElement).style.display =
                'block'
            }
          }
        }
      }
    })
  }

  setDaysUntilAvailableMessage = (lockedLectureContainerElement) => {
    if (lockedLectureContainerElement) {
      const lectureDripDaysElement = lockedLectureContainerElement.querySelector(
        DRIP_SECTION_SELECTOR
      )

      if (lectureDripDaysElement) {
        const lockedDripDays = lectureDripDaysElement.getAttribute(
          DAYS_UNTIL_DRIPPED_ATTR
        )
        const lockedDripDaysContainer = lockedLectureContainerElement.querySelector(
          DAYS_TO_DRIP_SELECTOR
        )

        if (lockedDripDaysContainer && lockedDripDays) {
          lockedDripDaysContainer.innerHTML = lockedDripDays
        }
      }
    }
  }

  removeContinueButton = (courseMainbar, lectureCompleteButton) => {
    if (lectureCompleteButton) {
      if (
        courseMainbar &&
        courseMainbar.classList.contains(LOCKED_DRIP_CLASS)
      ) {
        lectureCompleteButton.classList.add('hidden')
      } else {
        lectureCompleteButton.classList.remove('hidden')
      }
    }
  }
}
