////////////////////////////////////////////////////////////
// For traversing up a DOM tree, this function will continue
// to check the parentNode until the function passed as the
// second argument evaluates to true or until it runs out of
// nodes to check, which in this case means we've reached
// the <html> element.

type El = HTMLElement
type Ev = ($el) => boolean

export default function findAncestor($el: El, evaluator: Ev): El {
  let $result

  const check = ($current) => {
    if (evaluator($current)) {
      $result = $current
    }

    // Note to future devs, always use parentElement and not parentNode because
    // parentNode includes the document.
    if (!$result && $current.parentElement) {
      check($current.parentElement)
    }
  }

  check($el)

  return $result
}
