const values = require('object.values')
const entries = require('object.entries')

;(function polyfill() {
  if (!Object.values) {
    Object.values = values.shim()
  }

  if (!Object.entries) {
    Object.entries = entries.shim()
  }
})()
