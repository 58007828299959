/**
 * @file
 * On the students' side we don't have full page refreshes, only the page:change
 * event. This means scripts included in lecture attachments that use
 * document.write (like Gist embeds) won't run when students are navigating
 * through a course. This file corrects that problem by running each script
 * through postscribe.
 */

/* globals $ */

import postscribe from 'postscribe'

/**
 * The list of known offenders. If you need to add an entry, write a regular
 * expression that matches the provider's URL.
 */
const KNOWN_DOCUMENT_WRITE_ABUSERS = [
  /**
   * @see https://gist.github.com/
   */
  /\/\/gist\.github\.com/,

  /**
   * This video shows the embed code so you don't have to create an account.
   *
   * @see https://www.mailerlite.com/video-tutorials/embed-webform
   */
  /\/\/app\.mailerlite.com\/data\/webforms/,

  /**
   * @see https://chatra.io/
   */
  /\/\/.*?chatra\.io/,
]

const LECTURE_CHANGE = 'lecture:change'
const PAGE_CHANGE = 'page:change'
const PREFIX = 'teachableGistRefresher'
const SCRIPT_QUERY = 'script[src]:not([data-initialized])'

let elIndex = 0

/**
 * Pass it a URL string and test to see if it's from a known document.write
 * abuser.
 *
 * @param   {String}  url
 * @returns {Boolean}
 */
function getIsKnownDocumentWriteAbuser(url) {
  return !KNOWN_DOCUMENT_WRITE_ABUSERS.every((jerk) => !jerk.test(url))
}

function init() {
  $(SCRIPT_QUERY).each((index, current) => {
    const url = $(current).attr('src')

    if (!url) {
      return
    }

    if (!getIsKnownDocumentWriteAbuser(url)) {
      // Let it be.
      return
    }

    $(current).attr('initialized', true)

    const id = `${PREFIX}-${(elIndex += 1)}`
    const script = current.outerHTML
    const temporaryCache = {}

    $(current).after(`<div id="${id}"></div>`)
    $(current).remove()

    postscribe(`#${id}`, script, {
      beforeWrite(markup) {
        if (temporaryCache[markup]) {
          return ''
        }

        // Build a temp cache of markup so 2 identical tags don't get placed on
        // the page in a row. This sometimes happens with Github Gists.
        temporaryCache[markup] = true

        return markup
      },
    })
  })
}

$(document).on(LECTURE_CHANGE, init)

// This let is here because we want this script to run on "page:change" but not
// on the very first instance of that event. Only subsequent "page:change"
// events will trigger the init function.
let isDynamicPage = false
$(document).on(PAGE_CHANGE, () => {
  if (!isDynamicPage) {
    isDynamicPage = true
    return
  }

  init()
})
